import {Injectable} from '@angular/core'
import {Location} from '@angular/common'
import {Router, UrlCreationOptions, UrlSerializer} from '@angular/router'

import {environment} from '@env'

@Injectable({
  providedIn: 'root',
})
export class LinkService {

  constructor(
    private router: Router,
    private location: Location,
    private urlSerializer: UrlSerializer,
  ) {
  }

  buildLink(commands: any[], navigationExtras?: UrlCreationOptions) {
    const tree = this.router.createUrlTree(commands, navigationExtras)
    return environment.uiHost + this.location.prepareExternalUrl(this.urlSerializer.serialize(tree))
  }

  buildAlternateLink(commands: any[], navigationExtras?: UrlCreationOptions) {
    const tree = this.router.createUrlTree(commands, navigationExtras)
    return environment.uiHost + this.urlSerializer.serialize(tree).toString()
  }

  openLink(commands: any[], navigationExtras?: UrlCreationOptions) {
    window.location.href = this.buildLink(commands, navigationExtras)
  }
}
